import SocialIcons from "../../components/SocialIcons";
import React from "react";
import { useNavigate } from "react-router-dom";

const Landing = () => {
  const navigate = useNavigate();

  const navigateToAbout = () => {
    navigate("/about");
  };

  const navigateToNow = () => {
    navigate("/now");
  };

  return (
    <div className="full-screen-landing">
      <div className="landing">
        <div className="textContainer">
          <span className="role-title">Hi 👋 Glad to have you here!</span>
          <div className="bottom-container">
            <div className="bottom-button-container">
              <button
                className="text-button about-me-btn"
                onClick={navigateToAbout}
                aria-label="Learn more about me"
              >
                About Me
                <i className="fas fa-arrow-right" aria-hidden="true"></i>
              </button>
              <button
                className="text-button about-me-btn"
                onClick={navigateToNow}
                aria-label="Learn more about me"
              >
                🌱 What I’m Growing Into
                <i className="fas fa-arrow-right" aria-hidden="true"></i>
              </button>
              {/*  <button
                className="text-button about-me-btn"
                onClick={navigateToPortfolio}
                aria-label="Learn more about me"
              >
                {`</>`} Portfolio
                <i className="fas fa-arrow-right" aria-hidden="true"></i>
              </button> */}
            </div>
            <SocialIcons />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
