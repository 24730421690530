import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import Project from "../../components/Project";
import PageHeader from "../../components/PageHeader";
import PortfolioCategory from "../../types/portfolio";
import ProjectSpecialist from "../../components/ProjectSpecialist";

// Custom titles for each group (only for ProjectSpecialist)
const groupTitles = {
  mobile: "📱 Mobile Mastery",
  frontend: "🎨 Some Frontend Code I Wrote",
  backend: "⚙️ Some Backend Work I Did",
};

// Function to group only ProjectSpecialist data
const groupData = (data) => {
  return data.reduce((acc, item) => {
    if (!acc[item.type]) {
      acc[item.type] = { title: groupTitles[item.type], data: [] };
    }
    acc[item.type].data.push(item);
    return acc;
  }, {});
};

// Portfolio component
const Portfolio = ({ id, data, title, description }) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const [showAll, setShowAll] = useState(false);

  // Filter out ProjectSpecialist items for grouping
  const specialistProjects = data.filter(
    (item) => id === PortfolioCategory.Specialist.id
  );
  const otherProjects = data.filter(
    (item) => id !== PortfolioCategory.Specialist.id
  );

  // Group only the ProjectSpecialist data
  const groupedData = Object.values(groupData(specialistProjects));

  // Toggle Show All
  const handleShowAll = () => setShowAll(true);

  // Use useCallback to avoid recreating handleClick on every render
  const handleClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const renderPortfolioComponent = (id, project) => {
    if (id === PortfolioCategory.Specialist.id) {
      return <ProjectSpecialist key={project.id} project={project} />;
    }
    return <Project key={project.id} project={project} />;
  };

  const previewText = description
    .substring(0, description.lastIndexOf(" ", 135))
    .trim(); // Cut at nearest space to avoid breaking words

  return (
    <div className="full-screen-generic">
      <div className="portfolio">
        <PageHeader title={`${title} </>`} handleClick={handleClick} />
        <h2 className="portfolio-description">
          {isExpanded ? (
            description
          ) : (
            <>
              {previewText}...{" "}
              <span onClick={() => setIsExpanded(true)} className="read-more">
                ➜ Continue Reading
              </span>
            </>
          )}
        </h2>

        <div className="portfolio-groups">
          {/* Render grouped ProjectSpecialist components */}
          {id === PortfolioCategory.Specialist.id &&
            groupedData.map((group) => {
              const visibleProjects = showAll
                ? group.data
                : group.data.slice(0, 6);
              return (
                <div key={group.title} className="portfolio-group">
                  <h2 className="group-title">{group.title}</h2>
                  <div className="row">
                    {visibleProjects.map((project) =>
                      renderPortfolioComponent(id, project)
                    )}
                  </div>
                  {/* Show All button (only if more than 6 projects and not already showing all) */}
                  {!showAll && group.data.length > 6 && (
                    <button className="show-all-btn" onClick={handleShowAll}>
                      Show All
                    </button>
                  )}
                </div>
              );
            })}

          {/* Render other projects as usual (not grouped) */}
          <div className="row">
            {otherProjects.map((project) =>
              renderPortfolioComponent(id, project)
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
