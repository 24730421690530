const PortfolioCategory = {
  ReactNativeDev: {
    id: "react-native-dev",
    description: `Hey, thanks for stopping by! Below, you'll find a selection of projects I've worked on over the years. Some are thriving, some have faded, and others are still fighting to survive. These projects can give you an idea of what I’ve been up to so far and how I approach challenges and innovation.

    I’ve been working with React Native for quite some time now, and I truly love what I do 🖤📱. I'm passionate about building mobile apps and solving problems, and I'm great at it.`,
    title: "React Native Mastery",
  },
  FrontendShowcase: {
    id: "frontend-showcase",
    description:
      "A collection of frontend development projects demonstrating UI/UX skills.",
    title: "Crafting Interfaces",
  },
  MobileDevPortfolio: {
    id: "mobile-dev-portfolio",
    description:
      "Portfolio of mobile app development across various platforms.",
    title: "Mobile Portfolio",
  },
  FullstackCaseStudies: {
    id: "fullstack-case-studies",
    description:
      "Comprehensive case studies on full-stack development projects.",
    title: "Full-Stack Story",
  },
  Specialist: {
    id: "specialist",
    description: `Hey, thanks for stopping by! Below, you'll find a selection of projects I've worked on over the years. Some are thriving, some have faded, and others are still fighting to survive. They can give you an idea of what I’ve been up to so far. I’ve collaborated with companies, agencies, and startups, as well as worked on personal and open-source projects.
      
      If you have an idea or any questions and you are looking for a talented software developer 👨‍💻, I’m open to collaborations built on trust, high impact, and long-term success. If you’re looking for a reliable partner to bring your ideas to life, let’s connect! 🤝 `,
    title: "Projects I Brought to Life: Backend, Frontend, and Mobile",
  },
};

export default PortfolioCategory;
