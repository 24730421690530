import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import Landing from "../pages/landing/Landing";
import About from "../pages/about/About";
import Portfolio from "../pages/portfolio/Portfolio";
import PageNotFound from "../pages/404/PageNotFound";
import React from "react";
import Now from "../pages/now/Now";
import Blog from "../pages/blog/Blog";
import BlogDetail from "../pages/blog/BlogDetail";
import reactNativeProjects from "../data/portfolios/rnd_portfolio.json";
import frontendProjects from "../data/portfolios/fd_portfolio.json";
import fullStackProjects from "../data/portfolios/fsd_portfolio.json";
import mobileProjects from "../data/portfolios/md_portfolio.json";
import specialistProjects from "../data/portfolios/s_portfolio.json";
import PortfolioCategory from "../types/portfolio"; // Adjust path as needed

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <Routes location={location} key={location.pathname}>
      <Route path="/" element={<Landing />} />
      <Route path="/about" element={<About />} />
      <Route path="/now" element={<Now />} />
      <Route
        path={`/${PortfolioCategory.ReactNativeDev.id}`}
        element={
          <Portfolio
            id={PortfolioCategory.ReactNativeDev.id}
            data={reactNativeProjects}
            title={PortfolioCategory.ReactNativeDev.title}
            description={PortfolioCategory.ReactNativeDev.description}
          />
        }
      />
      <Route
        path={`/${PortfolioCategory.FrontendShowcase.id}`}
        element={
          <Portfolio
            id={PortfolioCategory.FrontendShowcase.id}
            data={frontendProjects}
            title={PortfolioCategory.FrontendShowcase.title}
            description={PortfolioCategory.FrontendShowcase.description}
          />
        }
      />
      <Route
        path={`/${PortfolioCategory.MobileDevPortfolio.id}`}
        element={
          <Portfolio
            id={PortfolioCategory.MobileDevPortfolio.id}
            data={mobileProjects}
            title={PortfolioCategory.MobileDevPortfolio.title}
            description={PortfolioCategory.MobileDevPortfolio.description}
          />
        }
      />
      <Route
        path={`/${PortfolioCategory.FullstackCaseStudies.id}`}
        element={
          <Portfolio
            id={PortfolioCategory.FullstackCaseStudies.id}
            data={fullStackProjects}
            title={PortfolioCategory.FullstackCaseStudies.title}
            description={PortfolioCategory.FullstackCaseStudies.description}
          />
        }
      />
      <Route
        path={`/${PortfolioCategory.Specialist.id}`}
        element={
          <Portfolio
            id={PortfolioCategory.Specialist.id}
            data={specialistProjects}
            title={PortfolioCategory.Specialist.title}
            description={PortfolioCategory.Specialist.description}
          />
        }
      />
      <Route path="/blog" element={<Blog />} />
      <Route path="/blog/:id" element={<BlogDetail />} />
      <Route path="/page-not-found" element={<PageNotFound />} />
      <Route path="*" element={<Navigate to="/page-not-found" />} />
    </Routes>
  );
};

export default AnimatedRoutes;
