import aboutMeImg from "../images/aboutme.jpg";
import React from "react";
import { email } from "../types/constants";

const AboutMe = () => {
  return (
    <div className="contentDescription">
      <div>
        <img src={aboutMeImg} alt={"pp"} className="personalImage" />
      </div>
      <h4>Hi!</h4>
      <br />
      <h2 className="description">
        <span className="wave">👋</span> I'm Ihsan.
      </h2>
      <br />
      <span>
        I’m a Software Developer who thrives on building apps from scratch,
        crafting every detail is my favorite part. 👨‍💻
      </span>
      <br /> <br />
      <span>
        I have a strong interest in cultural enrichment, check out my
        <span className="italic-text"> museum app </span>
      </span>
      <a href="https://museumsaround.com">here</a>
      <br /> <br />
      <p className="infoDescription">
        I'm open to opportunities and collaborations. Feel free to reach out!
      </p>
      <button
        className="text-button about-me-btn"
        onClick={() => (window.location.href = `mailto:${email}`)} // Trigger mailto on button click
      >
        Email
        <i className="fas fa-arrow-right"></i> {/* Example of an icon */}
      </button>
    </div>
  );
};

export default AboutMe;
