import React from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
// @ts-ignore
import blogPosts from "../blog/blogs.json";

const BlogPost = ({ id, title, excerpt, date, onClick }) => (
  <div className="blog-post" onClick={() => onClick(id)}>
    <h2 className="blog-title">{title}</h2>
    <span className="blog-date">{date}</span>
    <p className="blog-excerpt">{excerpt}</p>
  </div>
);

const BlogList = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const handlePostClick = (id) => {
    navigate(`/blog/${id}`);
  };

  return (
    <div className="blog-container">
      <div className="blog-content">
        <PageHeader
          title="Explorations & Reflections"
          handleClick={handleBack}
        />
        <span className="blog-description">
          A personal digital corner where curiosity knows no bounds. Here, I
          reflect, learn, and wander through topics that excite me.
        </span>
        <div className="blog-list">
          {blogPosts.map((post) => (
            <BlogPost
              key={post.id}
              id={post.id}
              title={post.title}
              excerpt={post.excerpt}
              date={post.date}
              onClick={handlePostClick}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogList;

// CSS Styling
const styles = `
.blog-container {
  display: flex;
  min-height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: flex-start;
  padding: 50px 20px 100px;
  overflow-y: auto;
  background: linear-gradient(to bottom, var(--bg-color) 0%, rgba(0, 0, 0, 0.8) 100%);
}

.blog-content {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.blog-description {
  margin-bottom: 20px;
  font-size: 1.2rem;
  color: #ddd;
}

.blog-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.blog-post {
  background: rgba(255, 255, 255, 0.1);
  padding: 15px;
  border-radius: 8px;
  backdrop-filter: blur(10px);
  transition: transform 0.2s, background 0.3s;
  cursor: pointer;
}

.blog-post:hover {
  transform: scale(1.02);
  background: rgba(255, 255, 255, 0.2);
}

.blog-title {
  font-size: 1.5rem;
  color: #fff;
}

.blog-date {
  font-size: 0.9rem;
  color: #bbb;
  display: block;
  margin-bottom: 5px;
}

.blog-excerpt {
  font-size: 1rem;
  color: #ccc;
}

@media (max-width: 768px) {
  .blog-content {
    width: 100%;
  }
}`;

document.head.insertAdjacentHTML("beforeend", `<style>${styles}</style>`);
