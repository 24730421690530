import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
// @ts-ignore
import blogPosts from "../blog/blogs.json";

const BlogDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const post = blogPosts.find((post) => post.id === parseInt(id));

  if (!post) {
    return <div className="error-message">Post not found!</div>;
  }

  return (
    <div className="blog-detail-container">
      <PageHeader title={post.title} handleClick={() => navigate(-1)} />
      <span className="blog-date">{post.date}</span>
      <p className="blog-content">{post.content}</p>
    </div>
  );
};

export default BlogDetail;

// CSS Styling
const styles = `
.blog-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
  max-width: 800px;
  margin: auto;
  color: #fff;
}

.blog-date {
  font-size: 1rem;
  color: #bbb;
  margin-bottom: 15px;
}

.blog-content {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #ddd;
}

.error-message {
  text-align: center;
  font-size: 1.5rem;
  color: #ff6b6b;
  margin-top: 50px;
}
`;

document.head.insertAdjacentHTML("beforeend", `<style>${styles}</style>`);