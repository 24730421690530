import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import {
  faInfoCircle,
  faCode,
  faTools,
  faLink,
  faImages,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import companies from "../data/companies.json";

// Separate styles into a dedicated object
const styles = {
  sectionIcon: {
    marginRight: 10,
  },
  screenshots: {
    display: "flex",
    gap: "15px",
    overflowX: "auto",
    padding: "10px 0",
    marginBottom: "15px",
  },
  screenshot: {
    minWidth: "200px",
    height: "400px",
    borderRadius: "12px",
    objectFit: "cover",
    border: "1px solid rgba(255, 255, 255, 0.1)",
    cursor: "pointer",
    transition: "transform 0.3s ease",
  },
  text: {
    italic: {
      fontStyle: "italic",
      fontSize: "12px",
      color: "#7f7f7f",
    },
    italicWhite: {
      fontStyle: "italic",
      fontSize: "12px",
      color: "#fff",
    },
  },
  projectCard: {
    base: {
      height: "135px",
      padding: "20px",
      transition: "all 0.3s ease-in-out",
      cursor: "pointer",
      position: "relative",
      overflow: "hidden",
      border: "1px solid transparent",
    },
    hover: {
      transform: "translateY(-5px)",
      boxShadow: "0 10px 20px rgba(0,0,0,0.2)",
    },
  },
  cardContent: {
    position: "relative",
    zIndex: 2,
  },
  techBadge: {
    display: "inline-block",
    padding: "4px 8px",
    borderRadius: "12px",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    marginRight: "8px",
    marginBottom: "8px",
    fontSize: "12px",
    color: "#fff",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "linear-gradient(45deg, rgba(0,0,0,0.1), rgba(0,0,0,0))",
    zIndex: 1,
  },
  sectionTitle: {
    color: "#fff",
    fontSize: "1.2rem",
    marginBottom: "1rem",
    marginTop: "1.5rem",
  },
  contributionItem: {
    backgroundColor: "rgba(255, 255, 255, 0.05)",
    padding: "12px",
    borderRadius: "8px",
    marginBottom: "8px",
  },
};

// Separate LinkList into its own component
const LinkList = ({ links }) => {
  const isValidUrl = (url) => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "\n]+$/;
    return urlRegex.test(url);
  };

  return links.map((item, index) => (
    <div key={index} style={{ marginBottom: 10 }}>
      <p className="tech text-center">
        <em style={styles.text.italic}>{item.title}:</em>
      </p>
      <p className="tech text-center">
        {isValidUrl(item.link) ? (
          <a href={item.link} target="_blank" rel="noopener noreferrer">
            <em>{item.link}</em>
          </a>
        ) : (
          <em>{item.link}</em>
        )}
      </p>
      <p className="tech text-center">
        <em style={styles.text.italic}>{item.description}</em>
      </p>
    </div>
  ));
};

// Separate ProjectCard into its own component
const ProjectCard = ({ project, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const company = companies.find((company) => company.id === project.companyId);

  const cardStyle = {
    ...styles.projectCard.base,
    backgroundColor: company.bgcolor,
    ...(isHovered && styles.projectCard.hover),
  };

  return (
    <div
      className="projectCard d-flex flex-column align-items-start justify-content-center"
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className="project-header"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <h3 className="projectTitle">{project.title}</h3>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
          }}
        >
          <h2 style={{ fontSize: "0.65rem", fontWeight: "normal", margin: 0 }}>
            ({project.category})
          </h2>
        </div>
      </div>
      <div className="tech-stack">
        {project.technologies
          .split(",")
          .slice(0, 5)
          .map((tech, index) => (
            <span key={index} style={styles.techBadge}>
              {tech.trim()}
            </span>
          ))}
      </div>
    </div>
  );
};

const ProjectSpecialist = ({ project }) => {
  const [ref, inView] = useInView({ threshold: 0.5, triggerOnce: true });
  const [isHovered, setIsHovered] = useState(false);

  if (!project) {
    return null;
  }

  const cardStyle = {
    ...styles.projectCard.base,
    backgroundColor: "rgba(255, 255, 255, 0.075)",
    position: "relative",
    height: "230px",
    display: "flex",
    flexDirection: "column",
    ...(isHovered && styles.projectCard.hover),
  };

  // Update the link handling
  const projectLink = project.links?.find(
    (link) =>
      link.type === "website" || link.type === "web" || link.type === "github"
  );

  const handleClick = () => {
    if (projectLink?.link) {
      window.open(projectLink.link, "_blank", "noopener,noreferrer");
    }
  };

  // Add a function to get link icon
  const getLinkIcon = (linkType) => {
    switch (linkType) {
      case "github":
        return <FontAwesomeIcon icon={faGithub} />;
      case "web":
      case "website":
        return <FontAwesomeIcon icon={faGlobe} />;
      default:
        return <FontAwesomeIcon icon={faLink} />;
    }
  };

  return (
    <div ref={ref} className="col-sm-12 col-lg-6">
      <div
        style={{
          ...cardStyle,
          cursor: projectLink ? "pointer" : "default",
        }}
        className="projectCard"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleClick}
        role="button"
        tabIndex={0}
      >
        <div
          className="project-header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: "0 0 10px 0",
            borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
            gap: "12px",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flex: 1,
              minWidth: 0,
            }}
          >
            {project.projectLogo && (
              <div
                style={{
                  width: "32px",
                  height: "32px",
                  marginRight: "12px",
                  flexShrink: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "rgba(255, 255, 255, 0.03)",
                  borderRadius: "8px",
                  padding: "4px",
                  overflow: "hidden",
                  border: "1px solid rgba(255, 255, 255, 0.05)",
                }}
              >
                <img
                  src={project.projectLogo}
                  alt={`${project.title} logo`}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    width: "auto",
                    height: "auto",
                    objectFit: "contain",
                    filter: "brightness(0.95)",
                    borderRadius: "4px",
                    transition: "transform 0.2s ease",
                  }}
                  onError={(e) => {
                    e.currentTarget.style.opacity = "0.5";
                  }}
                />
              </div>
            )}
            <h3
              className="projectTitle"
              style={{
                margin: 0,
                fontSize: "18px",
                fontWeight: "600",
                color: "#fff",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                flex: 1,
                minWidth: 0,
                maskImage:
                  "linear-gradient(to right, black 90%, transparent 100%)",
                WebkitMaskImage:
                  "linear-gradient(to right, black 90%, transparent 100%)",
              }}
            >
              {project.title}
            </h3>
          </div>
          {project.category && (
            <span
              style={{
                fontSize: "12px",
                color: "#999",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                padding: "4px 8px",
                borderRadius: "12px",
                flexShrink: 0,
              }}
            >
              {project.category}
            </span>
          )}
        </div>

        <div
          style={{
            flex: 1,
            padding: "10px 0",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p
            className="project-description"
            style={{
              color: "#ccc",
              marginBottom: "15px",
              fontSize: "14px",
              lineHeight: "1.5",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              textOverflow: "ellipsis",
            }}
          >
            {project.description}
          </p>

          <div
            className="tech-stack-container"
            style={{
              marginTop: "auto",
              position: "relative",
              width: "100%",
              overflow: "hidden",
            }}
          >
            <div
              className="tech-stack"
              style={{
                display: "flex",
                overflowX: "auto",
                whiteSpace: "nowrap",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                paddingBottom: "5px",
                maskImage:
                  "linear-gradient(to right, black 90%, transparent 100%)",
                WebkitMaskImage:
                  "linear-gradient(to right, black 90%, transparent 100%)",
              }}
            >
              {project.technologies
                ?.split(",")
                .slice(0, 5)
                .map((tech, index) => (
                  <span
                    key={index}
                    style={{
                      ...styles.techBadge,
                      marginBottom: 0,
                      display: "inline-block",
                      flexShrink: 0,
                    }}
                  >
                    {tech.trim()}
                  </span>
                ))}
            </div>
          </div>
        </div>

        {/* Add link type indicator */}
        {projectLink && (
          <div
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              fontSize: "12px",
              color: "rgba(255, 255, 255, 0.6)",
              padding: "4px 8px",
              borderRadius: "4px",
              backgroundColor: "rgba(255, 255, 255, 0.05)",
              opacity: isHovered ? 1 : 0,
              transition: "opacity 0.2s ease",
            }}
          >
            {getLinkIcon(projectLink.type)}
          </div>
        )}
      </div>

      <style>
        {`
          .tech-stack::-webkit-scrollbar {
            display: none;
          }
          .projectCard:hover {
            ${projectLink ? "cursor: pointer;" : ""}
          }
        `}
      </style>
    </div>
  );
};

export default ProjectSpecialist;
