import React, { useEffect } from "react";
import PageHeader from "../../components/PageHeader";
import { useNavigate } from "react-router-dom";
import UpToWhat from "../../components/UpToWhat";

const Now = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.querySelector(".now-content").classList.add("fade-in");
  }, []);

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <div className="full-screen-generic">
      <div className="now-content">
        <PageHeader title="The Path 🌿" handleClick={handleClick} />
        <UpToWhat />
      </div>
    </div>
  );
};

export default Now;
